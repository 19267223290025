import React, { useState, useEffect } from "react";
import { DIAMOND } from "../Libraries/Constants";
import { ethers } from "../FarmEncoder";

const SignatureCreator = ({ signer }) => {
  const [error, setError] = useState("");
  const [signature, setSignature] = useState("");
  const [chainId, setChainId] = useState(null);
  const [verifyingContract, setVerifyingContract] = useState(null);

  // State for permit parameters
  const [name, setName] = useState("");

  // Get chain ID on component mount
  useEffect(() => {
    const getChainId = async () => {
      try {
        if (signer) {
          const network = await (await signer).provider.getNetwork();
          setChainId(network.chainId);
        }
      } catch (err) {
        console.error("Error getting chain ID:", err);
        setError("Failed to get chain ID. Please make sure your wallet is connected.");
      }
    };
    getChainId();
  }, [signer]);

  const handleSignPermit = async () => {
    if (!signer) {
      setError("Signer not available. Please make sure your wallet is connected.");
      return;
    }

    if (!chainId) {
      setError("Chain ID not available. Please make sure your wallet is connected.");
      return;
    }

    try {
      const resolvedSigner = await signer;
      const address = await resolvedSigner.getAddress();

      // Create domain data
      const domain = {
        name: name,
        version: "1",
        chainId: chainId,
        verifyingContract: verifyingContract,
      };

      // Define the types
      const types = {
        Permit: [
          { name: "owner", type: "address" },
          { name: "spender", type: "address" },
          { name: "value", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "deadline", type: "uint256" },
        ],
      };

      // Create the data to sign
      const value = {
        owner: address,
        spender: DIAMOND,
        value: ethers.MaxUint256,
        nonce: 0,
        deadline: ethers.MaxUint256,
      };

      // Ethers v6 syntax for signing
      const sig = await resolvedSigner.signTypedData(domain, types, value);
      setSignature(sig);
      setError("");
    } catch (err) {
      console.error("Error signing permit:", err);
      setError(err.message);
    }
  };

  return (
    <div className="bg-gray-800 p-4 rounded-md">
      <h3 className="text-xl font-semibold text-green-400 mb-4">Sign Permit</h3>

      <div className="mb-4">
        <label htmlFor="name" className="block text-white mb-2">
          Token Name:
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full bg-gray-700 text-white p-2 rounded-md"
          placeholder="Enter name"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="verifyingContract" className="block text-white mb-2">
          Verifying Contract:
        </label>
        <input
          type="text"
          id="verifyingContract"
          value={verifyingContract}
          onChange={(e) => setVerifyingContract(e.target.value)}
          className="w-full bg-gray-700 text-white p-2 rounded-md"
          placeholder="Enter verifying contract address"
        />
      </div>

      <button
        onClick={handleSignPermit}
        className="bg-green-500 hover:bg-green-600 text-white p-2 rounded-md w-full mb-4"
      >
        Sign Permit
      </button>

      {signature && (
        <div className="mt-4">
          <h4 className="text-white mb-2">Generated Signature:</h4>
          <div className="bg-gray-700 p-2 rounded-md break-all text-white">{signature}</div>
        </div>
      )}

      {error && <div className="mt-4 text-red-500">Error: {error}</div>}
    </div>
  );
};

export default SignatureCreator;

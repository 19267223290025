import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import ClipboardData from "./ClipboardData";
import { CONTRACT_TYPES, KNOWN_ADDRESSES, ADDRESS_NAMES } from "../config/contractTypes";

// Move these constants outside the component
const INCLUDED_FUNCTIONS = [
  "totalSupply",
  "balanceOf",
  "balanceOfStalk",
  "approve",
  "transfer",
  "swapFrom",
  "getSwapOut",
  "swapTo",
  "getSwapIn",
  "shift",
  "getShiftOut",
  "addLiquidity",
  "getAddLiquidityOut",
  "removeLiquidity",
  "getRemoveLiquidityOut",
  "removeLiquidityOneToken",
  "getRemoveLiquidityOneTokenOut",
  "removeLiquidityImbalanced",
  "getRemoveLiquidityImbalancedIn",
  "sync",
  "getSyncOut",
  "skim",
  "transferToken",
  "getReserves",
  "checkForBothThings",
  "check",
  "checkLogic",
  "callBSandCheckValue",
  "callBSandCheckValue2",
  "plantAndConvert",
  "harvestAndSow",
  "add",
  "sub",
  "mul",
  "div",
  "mod",
  "mulDiv",
  "gt",
  "lt",
  "gte",
  "lte",
  "eq",
  "neq",
  "bytes32Switch",
];

const EXCLUDED_FUNCTIONS = [
  "renounceOwnership",
  "transferOwnership",
  "upgradeToAndCall",
  "upgradeTo",
  "addToWhitelist15759294",
  "removeFromWhitelist20828539",
  "setValidAction9163505",
  "advancedFarm6817479",
  "farm59459878",
  "plow1795336546",
  "plow346362236",
  "tractor40565404",
];

const AdvancedPipeCall = ({ availableFunctions, pipeCallData, onChange, onRemove }) => {
  const [target, setTarget] = useState(pipeCallData.target || "");
  const [selectedFunction, setSelectedFunction] = useState(pipeCallData.selectedFunction || "");
  const [functionInputs, setFunctionInputs] = useState(pipeCallData.functionInputs || []);

  // Create filteredFunctions using useMemo
  const filteredFunctions = useMemo(() => {
    return availableFunctions.filter((func) => {
      const isViewFunction = func.stateMutability === "view" || func.stateMutability === "pure";
      const isIncluded = INCLUDED_FUNCTIONS.includes(func.name);
      const isExcluded = EXCLUDED_FUNCTIONS.includes(func.name);

      return (!isViewFunction || isIncluded) && !isExcluded;
    });
  }, [availableFunctions]); // No need to include constants in dependency array

  // Filter functions based on contract type
  const functionsToShow = useMemo(() => {
    if (target) {
      // Convert target to lowercase for comparison
      const targetLower = target.toLowerCase();
      // Find the matching address (case-insensitive)
      const matchingAddress = Object.keys(KNOWN_ADDRESSES).find(
        (addr) => addr.toLowerCase() === targetLower
      );

      if (matchingAddress) {
        const contractType = KNOWN_ADDRESSES[matchingAddress];
        const allowedFunctions = CONTRACT_TYPES[contractType].allowedFunctions;
        return filteredFunctions.filter((func) => allowedFunctions.includes(func.name));
      }
    }
    return filteredFunctions;
  }, [target, filteredFunctions]);

  const [clipboard, setClipboard] = useState(
    pipeCallData.clipboard || {
      typeId: "0x02",
      returnPasteParams: [{ returnDataIndex: "", copyIndex: "", pasteIndex: "" }],
    }
  );

  // Effect to update function inputs based on selected function
  useEffect(() => {
    const selectedFuncObj = functionsToShow.find((func) => func.name === selectedFunction);

    if (selectedFuncObj && selectedFuncObj.inputs) {
      const newInputs = selectedFuncObj.inputs.map((input, index) => ({
        name: input.name,
        type: input.type,
        value: functionInputs[index]?.value || "",
      }));

      if (JSON.stringify(newInputs) !== JSON.stringify(functionInputs)) {
        setFunctionInputs(newInputs);
      }
    } else if (functionInputs.length !== 0) {
      setFunctionInputs([]);
    }
  }, [selectedFunction, functionsToShow, functionInputs]);

  // Ref to track the initial render
  const isFirstRender = useRef(true);

  // Memoize the onChange function
  const memoizedOnChange = useCallback(
    (updatedData) => {
      onChange(updatedData);
    },
    [onChange]
  );

  // Effect to update parent only after the first render
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const updatedPipeCall = {
      target,
      selectedFunction,
      functionInputs,
      clipboard,
    };

    // Only notify parent if there's an actual change
    const hasChanged =
      target !== pipeCallData.target ||
      selectedFunction !== pipeCallData.selectedFunction ||
      JSON.stringify(functionInputs) !== JSON.stringify(pipeCallData.functionInputs) ||
      JSON.stringify(clipboard) !== JSON.stringify(pipeCallData.clipboard);

    if (hasChanged) {
      memoizedOnChange(updatedPipeCall);
    }
  }, [target, selectedFunction, functionInputs, clipboard, memoizedOnChange, pipeCallData]);

  // Handle clipboard changes, updating specific fields in returnPasteParams
  const handleClipboardChange = (field, value) => {
    let updatedClipboard = { ...clipboard };

    if (field === "addReturnPasteParams") {
      updatedClipboard.returnPasteParams = [...updatedClipboard.returnPasteParams, value];
    } else if (field === "removeReturnPasteParams") {
      updatedClipboard.returnPasteParams = updatedClipboard.returnPasteParams.filter(
        (_, i) => i !== value
      );
    } else if (field === "typeId") {
      updatedClipboard.typeId = value;
      if (value === "0x01" && updatedClipboard.returnPasteParams.length > 1) {
        updatedClipboard.returnPasteParams = [updatedClipboard.returnPasteParams[0]];
      }
    } else {
      updatedClipboard[field] = value;
    }

    setClipboard(updatedClipboard);
    onChange({
      ...pipeCallData,
      clipboard: updatedClipboard,
    });
  };

  // Handle individual returnPasteParams input changes
  const handleClipboardDataChange = (paramIndex, field, value) => {
    const updatedClipboard = { ...clipboard };
    updatedClipboard.returnPasteParams = updatedClipboard.returnPasteParams.map((param, index) =>
      index === paramIndex ? { ...param, [field]: value } : param
    );
    setClipboard(updatedClipboard);
    onChange({
      ...pipeCallData,
      clipboard: updatedClipboard,
    });
  };

  // Handle function selection and set inputs accordingly
  const handleFunctionChange = (e) => {
    setSelectedFunction(e.target.value);
  };

  // Modify the handleInputChange function
  const handleInputChange = useCallback((index, value) => {
    setFunctionInputs((prevInputs) => {
      const updatedInputs = prevInputs.map((input, i) =>
        i === index ? { ...input, value } : input
      );
      return updatedInputs;
    });
  }, []);

  // Add new state for handling address selection mode
  const [addressInputMode, setAddressInputMode] = useState("common"); // 'common' or 'custom'

  // Create array of address options for the dropdown
  const commonAddressOptions = Object.entries(KNOWN_ADDRESSES).map(([address, type]) => ({
    value: address,
    label: `${ADDRESS_NAMES[address]} (${address})`,
  }));

  // Address input section
  const renderAddressInput = () => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Contract Address</label>
      <div className="mt-1">
        <div className="flex space-x-2 mb-2">
          <button
            onClick={() => setAddressInputMode("common")}
            className={`px-3 py-1 rounded ${
              addressInputMode === "common" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
            }`}
          >
            Common Addresses
          </button>
          <button
            onClick={() => setAddressInputMode("custom")}
            className={`px-3 py-1 rounded ${
              addressInputMode === "custom" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
            }`}
          >
            Custom Address
          </button>
        </div>

        {addressInputMode === "common" ? (
          <div className="space-y-2">
            <select
              value={target}
              onChange={(e) => setTarget(e.target.value)}
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md text-black bg-white"
            >
              <option value="" className="text-black">
                Select a common address
              </option>
              {commonAddressOptions.map((option) => (
                <option key={option.value} value={option.value} className="text-black">
                  {option.label}
                </option>
              ))}
            </select>
            {target && (
              <div className="text-black bg-gray-100 p-2 rounded-md break-all">
                Selected: {target}
              </div>
            )}
          </div>
        ) : (
          <input
            type="text"
            value={target}
            onChange={(e) => setTarget(e.target.value)}
            placeholder="Enter contract address (0x...)"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md text-black bg-white"
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="border border-gray-600 p-4 rounded-md mb-4 bg-gray-100">
      <h2 className="text-lg font-bold text-blue-600 mb-4">AdvancedPipeCall</h2>

      {renderAddressInput()}

      {/* Function Selection */}
      <div className="border border-gray-400 p-4 mb-4 bg-gray-50">
        <select
          value={selectedFunction}
          onChange={handleFunctionChange}
          className="p-2 rounded-md text-black w-full bg-blue-100 mb-4"
        >
          <option value="">Select Function</option>
          {functionsToShow.map((func) => (
            <option key={func.name} value={func.name}>
              {func.name}
            </option>
          ))}
        </select>

        {/* Function Inputs */}
        {functionInputs.length > 0 &&
          functionInputs.map((input, index) => (
            <div key={index} className="mb-2">
              <label className="block text-sm font-medium text-gray-700">
                {input.name} ({input.type})
              </label>
              <input
                type="text"
                placeholder={`Enter ${input.name}`}
                value={input.value}
                onChange={(e) => handleInputChange(index, e.target.value)}
                className="mt-1 p-2 rounded-md text-black w-full bg-blue-100"
              />
            </div>
          ))}
      </div>

      {/* Clipboard Section */}
      <h2 className="text-sm italic text-black mb-2">
        Clipboard allows for dynamic calldata. This is indexed as: (Call to copy from), (return
        param to copy), and (input to paste to). (1, 0, 0) means to copy the 0th return param from
        the 2nd call, and paste into the 0th param of this call.
      </h2>
      <h2 className="text-sm italic text-black mb-2">
        Copy and paste params are restricted to 32 byte sizes, and can only be copied from and
        pasted into 32 byte increments. User should be aware of how arrays are encoded to properly
        paste data.
      </h2>
      <h2 className="text-sm italic text-black mb-2">
        The AdvancedPipeCall Clipboard is different from the AdvancedFarmCall Clipboard.
      </h2>
      <div className="border border-gray-400 p-4 mb-4 bg-gray-50">
        <h3 className="text-md font-semibold text-blue-600 mb-2">Clipboard</h3>
        <ClipboardData
          clipboard={clipboard}
          onClipboardChange={handleClipboardChange}
          onClipboardDataChange={handleClipboardDataChange}
        />
      </div>

      {/* Remove Call Button */}
      <button
        onClick={() => {
          onRemove();
        }}
        className="mt-2 bg-red-500 hover:bg-red-600 text-white p-2 rounded-md w-full"
      >
        Remove Pipe Call
      </button>
    </div>
  );
};

export default AdvancedPipeCall;

const { ethers } = require("ethers");
const Helpers = require("../Libraries/Helpers");

class AdvancedFarm {
  constructor(abi) {
    this.beanstalk = new ethers.Interface(abi);
  }

  /**
   * @notice generates a single advanced farm call
   */
  generateAdvancedFarmCall(advancedFarmCallData) {
    const advancedFarmCalls = [];
    for (let i = 0; i < advancedFarmCallData.length; i++) {
      const { callData, clipboard } = Helpers.encodeCallDataAndClipboard(
        this.beanstalk,
        advancedFarmCallData[i].function,
        advancedFarmCallData[i].inputs,
        advancedFarmCallData[i].clipboard
      );
      advancedFarmCalls.push({ callData: callData, clipboard: clipboard });
    }
    return this.createAdvancedFarmCalls(advancedFarmCalls);
  }

  createAdvancedFarmCalls(advancedFarmCalls) {
    return this.beanstalk.encodeFunctionData("advancedFarm", [
      advancedFarmCalls,
    ]);
  }
}

module.exports = AdvancedFarm;

import React, { useState } from "react";

const AddContent = () => {
  const [selectedBlueprint, setSelectedBlueprint] = useState("");
  const [blueprintInputs, setBlueprintInputs] = useState({});

  const tractorBlueprints = [
    {
      id: 1,
      name: "Transfer Internal Beans",
      description:
        "Transfer Beans from one internal Balance to another. Takes in 2 tractor parameters (destination, amount)",
      inputLabel: "Destination, Amount",
      farmCall: 0x36bfafbd0000000000000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000040000000000000000000000000000000000000000000000000000000000000012000000000000000000000000000000000000000000000000000000000000000a46204aa43000000000000000000000000bea0005b8599265d41256905a9b3073d397812e400000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000000000000000010000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000000000,
    },
    {
      id: 2,
      name: "Fertilize Fields",
      description: "Apply fertilizer to all active fields",
      inputLabel: "Fertilizer Amount",
    },
    {
      id: 3,
      name: "Water Crops",
      description: "Water all planted crops",
      inputLabel: "Water Amount",
    },
    {
      id: 4,
      name: "Pest Control",
      description: "Apply pest control measures to protect crops",
      inputLabel: "Pesticide Type",
    },
  ];

  const handleBlueprintSelect = (blueprintId) => {
    setSelectedBlueprint(blueprintId === selectedBlueprint ? "" : blueprintId);
  };

  const handleInputChange = (blueprintId, value) => {
    setBlueprintInputs((prev) => ({ ...prev, [blueprintId]: value }));
  };

  const executeTractorBlueprint = () => {
    if (selectedBlueprint) {
      const blueprint = tractorBlueprints.find(
        (bp) => bp.id === selectedBlueprint
      );
      const inputValue = blueprintInputs[selectedBlueprint] || "";
      console.log(
        "Executing tractor blueprint:",
        blueprint,
        "with input:",
        inputValue
      );
      // Here you would implement the logic to execute the selected blueprint
      // This might involve calling a smart contract function or sending a transaction
    } else {
      console.log("No blueprint selected");
    }
  };

  return (
    <div className="bg-gray-800 p-6 rounded-md">
      <h2 className="text-2xl font-bold text-green-400 mb-4">
        Tractor Blueprints
      </h2>
      <div className="space-y-4 mb-4">
        {tractorBlueprints.map((blueprint) => (
          <div
            key={blueprint.id}
            className={`p-4 rounded-md ${
              selectedBlueprint === blueprint.id
                ? "bg-green-700"
                : "bg-gray-700"
            } transition-colors`}
          >
            <div
              className="cursor-pointer"
              onClick={() => handleBlueprintSelect(blueprint.id)}
            >
              <h4 className="text-lg font-semibold">{blueprint.name}</h4>
              <p className="text-sm text-gray-300">{blueprint.description}</p>
            </div>
            <div className="mt-2">
              <label
                htmlFor={`input-${blueprint.id}`}
                className="block text-sm font-medium text-gray-300"
              >
                {blueprint.inputLabel}:
              </label>
              <input
                type="text"
                id={`input-${blueprint.id}`}
                value={blueprintInputs[blueprint.id] || ""}
                onChange={(e) =>
                  handleInputChange(blueprint.id, e.target.value)
                }
                className="mt-1 block w-full rounded-md bg-gray-600 border-gray-500 text-white focus:border-green-500 focus:ring-green-500"
                placeholder={`Enter ${blueprint.inputLabel.toLowerCase()}`}
              />
            </div>
          </div>
        ))}
      </div>
      <button
        onClick={executeTractorBlueprint}
        className={`w-full py-2 px-4 rounded ${
          selectedBlueprint
            ? "bg-blue-500 hover:bg-blue-600 text-white font-bold"
            : "bg-gray-500 text-gray-300 cursor-not-allowed"
        }`}
        disabled={!selectedBlueprint}
      >
        Execute Selected Blueprint
      </button>
    </div>
  );
};

export default AddContent;

const { ethers } = require("ethers");
const Helpers = require("../Libraries/Helpers");
const gardenAbi = require("../Abi/Garden.json");
const wellAbi = require("../Abi/well.json");

class AdvancedPipe {
  constructor(targetAbis) {
    this.generalInterface = new ethers.Interface(
      Helpers.combineAbis([gardenAbi, wellAbi, ...targetAbis])
    );
  }
  /**
   * @notice Generates the callData for advancedPipe
   */
  genAdvPipeCall(advancedPipeCallData, etherValue) {
    return this.createAdvPipeCalls(this.createAdvPipeCallStruct(advancedPipeCallData), etherValue);
  }

  /**
   * @notice Creates the advancedPipeCall struct for each call
   */
  createAdvPipeCallStruct(advancedPipeCallData) {
    const advancedPipeCalls = [];
    for (let i = 0; i < advancedPipeCallData.length; i++) {
      const { callData, clipboard } = Helpers.encodeCallDataAndClipboard(
        this.generalInterface,
        advancedPipeCallData[i].function,
        advancedPipeCallData[i].inputs,
        advancedPipeCallData[i].clipboard
      );
      advancedPipeCalls.push({
        target: advancedPipeCallData[i].target,
        callData: callData,
        clipboard: clipboard,
      });
    }
    return advancedPipeCalls;
  }

  /**
   * @notice Creates the advancedPipeCalls for the pipeline
   */
  createAdvPipeCalls(advancedPipeCalls, etherValue) {
    return this.generalInterface.encodeFunctionData("advancedPipe", [
      advancedPipeCalls,
      etherValue,
    ]);
  }
}

module.exports = AdvancedPipe;

import React, { useState, useCallback, useEffect, memo } from "react";
import { BeanstalkFarm, beanstalkAbi, wellAbi, gardenAbi, ethers } from "./FarmEncoder";
import { rabbyKit } from "./rabbyConfig";
import { watchAccount, switchNetwork } from "@wagmi/core";
import { arbitrum } from "@wagmi/core/chains";
import FunctionCall from "./components/FunctionCall";
import AdvancedPipeCall from "./components/AdvancedPipeCall";
import { getFunctionNamesFromAbi } from "./utils/abiUtils";
import AbiInput from "./components/AbiInput";
import ClipboardData from "./components/ClipboardData";
import Tractor from "./components/Tractor";
import TractorExecutor from "./components/TractorExecutor";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import AddContent from "./components/AddContent";
import SignatureCreator from "./components/SignatureCreator";
const {
  DIAMOND,
  GARDEN,
  PINTO,
  PINTO_ETH,
  PINTO_CBETH,
  PINTO_CBBTC,
  PINTO_USDC,
  PINTO_WSOL,
} = require("../src/Libraries/Constants");

const App = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [signer, setSigner] = useState(null);
  const [calls, setCalls] = useState([
    {
      function: "",
      inputs: [""],
      pipeCalls: [
        {
          target: "",
          selectedFunction: "",
          functionInputs: [],
          clipboard: {
            typeId: "0x00",
            returnPasteParams: [{ returnDataIndex: "0", copyIndex: "0", pasteIndex: "0" }],
          },
        },
      ],
      clipboard: {
        typeId: "0x00",
        returnPasteParams: [{ returnDataIndex: "0", copyIndex: "0", pasteIndex: "0" }],
      },
      uint256Value: "",
    },
  ]);

  const allAbi = wellAbi;

  const [pipeCallAbis, setPipeCallAbis] = useState([wellAbi, gardenAbi]);
  const [encodedCallData, setEncodedCallData] = useState("");
  const [availableFunctions, setAvailableFunctions] = useState([]);
  const [availablePipeFunctions, setAvailablePipeFunctions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const connectWallet = async () => {
    let unwatch;
    try {
      await rabbyKit.open();

      await new Promise((resolve, reject) => {
        unwatch = watchAccount(async (account) => {
          if (account.isConnected) {
            try {
              if (account.connector.chains[0].id !== arbitrum.id) {
                await switchNetwork({ chainId: arbitrum.id });
              }

              if (typeof window.ethereum !== "undefined") {
                const provider = new ethers.BrowserProvider(window.ethereum);
                const walletSigner = await provider.getSigner();
                const address = await walletSigner.getAddress();
                setWalletAddress(address);
                // Ensure signer is fully resolved before setting
                const resolvedSigner = await Promise.resolve(walletSigner);
                setSigner(resolvedSigner);
              } else {
                console.error("window.ethereum is undefined");
                // Implement fallback strategy here if needed
              }
              resolve();
            } catch (error) {
              console.error("Error during connection:", error);
              reject(error);
            }
          } else {
            console.error("Wallet not connected");
          }
        });
      });
    } catch (err) {
      console.error("Error connecting wallet:", err.message);
    } finally {
      if (unwatch) unwatch();
      rabbyKit.close();
    }
  };

  const updateAvailableFunctions = useCallback(() => {
    const allAbis = [beanstalkAbi];
    const functions = allAbis.flatMap((abi) => getFunctionNamesFromAbi(abi));
    setAvailableFunctions(functions);
  }, []);

  const updateAvailablePipeFunctions = useCallback(() => {
    const allPipeAbis = [...pipeCallAbis];
    const pipeFunctions = allPipeAbis.flatMap((abi) => getFunctionNamesFromAbi(abi));
    setAvailablePipeFunctions(pipeFunctions);
  }, [pipeCallAbis]);

  useEffect(() => {
    updateAvailableFunctions();
    updateAvailablePipeFunctions();
  }, [updateAvailableFunctions, updateAvailablePipeFunctions]);

  const handleAdvancedPipeCallChange = useCallback((callIndex, pipeCallIndex, updatedPipeCall) => {
    setCalls((prevCalls) => {
      const updatedCalls = [...prevCalls];
      const updatedCall = { ...updatedCalls[callIndex] };
      const updatedPipeCalls = [...updatedCall.pipeCalls];

      updatedPipeCalls[pipeCallIndex] = {
        ...updatedPipeCalls[pipeCallIndex],
        ...updatedPipeCall,
        clipboard: {
          ...updatedPipeCalls[pipeCallIndex].clipboard,
          ...updatedPipeCall.clipboard,
          returnPasteParams: [...(updatedPipeCall.clipboard?.returnPasteParams || [])],
        },
      };

      updatedCall.pipeCalls = updatedPipeCalls;
      updatedCalls[callIndex] = updatedCall;

      return updatedCalls;
    });
  }, []);

  const handleAddCustomAbiForPipeCall = useCallback(
    (abi) => {
      setPipeCallAbis((prevAbis) => [...prevAbis, abi]);
      updateAvailablePipeFunctions();
    },
    [updateAvailablePipeFunctions]
  );

  const handleFunctionChange = (callIndex, selectedFunction) => {
    const updatedCalls = [...calls];
    updatedCalls[callIndex].function = selectedFunction;
    setCalls(updatedCalls);
  };

  const handleRemoveCall = (callIndex) => {
    setCalls(calls.filter((_, index) => index !== callIndex));
  };

  const handleAdvancedPipeCallSubmit = useCallback((callIndex, pipeCallStruct) => {
    setCalls((prevCalls) => {
      const updatedCalls = [...prevCalls];
      updatedCalls[callIndex].pipeCalls.push(pipeCallStruct);
      return updatedCalls;
    });
  }, []);

  const handleRemovePipeCall = (callIndex, pipeCallIndex) => {
    const updatedCalls = [...calls];
    updatedCalls[callIndex].pipeCalls = updatedCalls[callIndex].pipeCalls.filter(
      (_, index) => index !== pipeCallIndex
    );

    if (updatedCalls[callIndex].pipeCalls.length === 0) {
      updatedCalls.splice(callIndex, 1);
    }

    setCalls(updatedCalls);
  };

  const handleRemoveFarmCall = (callIndex) => {
    if (calls.length > 1) {
      setCalls(calls.filter((_, index) => index !== callIndex));
    }
  };

  const handleUint256Change = (callIndex, value) => {
    const updatedCalls = [...calls];
    updatedCalls[callIndex].uint256Value = value;
    setCalls(updatedCalls);
  };

  useEffect(() => {
    updateAvailableFunctions();
  }, [updateAvailableFunctions]);

  const handleClipboardChange = useCallback((callIndex, field, value) => {
    setCalls((prevCalls) => {
      const updatedCalls = [...prevCalls];
      const updatedCall = { ...updatedCalls[callIndex] };
      const clipboardCopy = { ...updatedCall.clipboard };

      if (field === "addReturnPasteParams") {
        clipboardCopy.returnPasteParams = [...clipboardCopy.returnPasteParams, value];
      } else if (field === "removeExtraReturnPasteParams") {
        clipboardCopy.returnPasteParams = [clipboardCopy.returnPasteParams[0]];
      } else if (field === "removeReturnPasteParams") {
        clipboardCopy.returnPasteParams = clipboardCopy.returnPasteParams.filter(
          (_, i) => i !== value
        );
      } else if (field === "typeId") {
        clipboardCopy.typeId = value;
      }

      updatedCall.clipboard = clipboardCopy;
      updatedCalls[callIndex] = updatedCall;

      return updatedCalls;
    });
  }, []);

  const handleClipboardDataChange = useCallback((callIndex, paramIndex, field, value) => {
    setCalls((prevCalls) => {
      const updatedCalls = [...prevCalls];
      const updatedCall = { ...updatedCalls[callIndex] };
      const updatedClipboard = { ...updatedCall.clipboard };

      updatedClipboard.returnPasteParams = updatedClipboard.returnPasteParams.map((param, index) =>
        index === paramIndex ? { ...param, [field]: value } : param
      );

      updatedCall.clipboard = updatedClipboard;
      updatedCalls[callIndex] = updatedCall;

      return updatedCalls;
    });
  }, []);

  useCallback(
    (index, field, value, subIndex = null) => {
      setCalls((prevCalls) => {
        const updatedCalls = [...prevCalls];

        if (field === "function") {
          const selectedFunction = availableFunctions.find((fn) => fn.name === value);
          updatedCalls[index].inputs = selectedFunction
            ? Array(selectedFunction.inputs.length).fill("")
            : [""];
          updatedCalls[index].function = value;
        } else if (subIndex !== null) {
          updatedCalls[index].inputs[subIndex] = value;
        } else {
          updatedCalls[index][field] = value;
        }

        return updatedCalls;
      });
    },
    [availableFunctions]
  );

  const addCall = useCallback(() => {
    setCalls((prevCalls) => [
      ...prevCalls,
      {
        function: "",
        inputs: [""],
        pipeCalls: [
          {
            target: "",
            selectedFunction: "",
            functionInputs: [],
            clipboard: {
              typeId: "0x01",
              returnPasteParams: [{ returnDataIndex: "0", copyIndex: "0", pasteIndex: "0" }],
            },
          },
        ],
        clipboard: {
          typeId: "0x01",
          returnPasteParams: [{ returnDataIndex: "0", copyIndex: "0", pasteIndex: "0" }],
        },
        uint256Value: "",
      },
    ]);
  }, []);

  useCallback(
    (index) => {
      if (calls.length > 1) {
        setCalls((prevCalls) => prevCalls.filter((_, i) => i !== index));
      }
    },
    [calls]
  );

  const generateCallData = useCallback(() => {
    const beanstalkFarm = new BeanstalkFarm([allAbi]);
    const callDataArray = calls.map((call) => {
      if (call.function === "advancedPipe") {
        const advancedPipeCalls = call.pipeCalls.map((pipeCall) => {
          return {
            target: pipeCall.target,
            function: pipeCall.selectedFunction,
            inputs: pipeCall.functionInputs,
            clipboard: pipeCall.clipboard,
          };
        });
        return {
          function: "advancedPipe",
          inputs: [beanstalkFarm.createAdvPipeCallStruct(advancedPipeCalls), 0],
          clipboard: call.clipboard,
        };
      } else {
        if (call.inputs[0] === "") {
          call.inputs = [];
        }
        return call;
      }
    });

    const aggregatedCallData = beanstalkFarm.generateAdvancedFarmCall(callDataArray);
    setEncodedCallData(aggregatedCallData);
  }, [calls, allAbi]);

  useEffect(() => {
    let unwatch;

    const setupAccountWatcher = async () => {
      unwatch = watchAccount(async (account) => {
        if (account.isConnected) {
          try {
            if (account.connector.chains[0].id !== arbitrum.id) {
              await switchNetwork({ chainId: arbitrum.id });
            }

            const provider = new ethers.BrowserProvider(window.ethereum);
            const walletSigner = await provider.getSigner();
            const address = await walletSigner.getAddress();

            setWalletAddress(address);
            setSigner(walletSigner);
          } catch (error) {
            console.error("Error during connection:", error);
          }
        } else {
          console.error("Wallet not connected");
        }
      });
    };

    setupAccountWatcher();

    return () => {
      if (unwatch) {
        unwatch();
      }
    };
  }, []);

  useEffect(() => {
    const updateSigner = async () => {
      if (walletAddress) {
        try {
          if (typeof window.ethereum !== "undefined") {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const newSigner = provider.getSigner();
            setSigner(newSigner);
          } else {
            console.error("window.ethereum is undefined in useEffect");
            // You might want to implement a fallback strategy here
          }
        } catch (error) {
          console.error("Error updating signer:", error);
        }
      }
    };

    updateSigner();
  }, [walletAddress]);

  const beanstalkAddresses = {
    [arbitrum.id]: DIAMOND,
    8453: DIAMOND,
    1337: DIAMOND,
  };

  const commonAddresses = {
    [arbitrum.id]: {
      Diamond: DIAMOND,
      Garden: GARDEN,
      Pinto: PINTO,
      PintoWeth: PINTO_ETH,
      PintoCbEth: PINTO_CBETH,
      PintoWbtc: PINTO_CBBTC,
      PintoWsol: PINTO_WSOL,
    },
    8453: {
      Diamond: DIAMOND,
      Garden: GARDEN,
      Pinto: PINTO,
      PintoWeth: PINTO_ETH,
      PintoCbEth: PINTO_CBETH,
      PintoWbtc: PINTO_CBBTC,
      PintoUsdc: PINTO_USDC,
      PintoWsol: PINTO_WSOL,
    },
    1337: {
      Diamond: DIAMOND,
      Garden: GARDEN,
      Pinto: PINTO,
      PintoWeth: PINTO_ETH,
      PintoCbEth: PINTO_CBETH,
      PintoWbtc: PINTO_CBBTC,
      PintoWsol: PINTO_WSOL,
    },
  };

  const [beanstalkContract, setBeanstalkContract] = useState(
    new ethers.Contract(beanstalkAddresses[arbitrum.id], beanstalkAbi, signer)
  );

  // Function to switch networks
  const handleNetworkSwitch = async (networkId) => {
    try {
      await switchNetwork({ chainId: networkId });
      const newAddress = beanstalkAddresses[networkId];
      if (newAddress) {
        setBeanstalkContract(new ethers.Contract(newAddress, beanstalkAbi, signer));
      }
    } catch (error) {
      console.error("Error switching network:", error);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Copied to clipboard:", text);
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  // Add this useEffect to set the signer when the wallet is connected
  useEffect(() => {
    const setupSigner = async () => {
      if (window.ethereum) {
        try {
          const provider = new ethers.BrowserProvider(window.ethereum);
          const newSigner = await provider.getSigner();
          setSigner(newSigner);
        } catch (error) {
          console.error("Error setting up signer:", error);
        }
      }
    };

    setupSigner();
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-gray-900 text-white p-6">
        <nav className="bg-gray-800 p-4 mb-6 rounded-md">
          <ul className="flex space-x-4">
            <li>
              <Link to="/" className="text-white hover:text-green-400">
                Home
              </Link>
            </li>
            <li>
              <Link to="/tractor-blueprints" className="text-white hover:text-green-400">
                Preset Tractor Blueprints
              </Link>
            </li>
            <li>
              <Link to="/approvals" className="text-white hover:text-green-400">
                Approvals
              </Link>
            </li>
          </ul>
        </nav>

        <div className="flex justify-end mb-4">
          <div className="relative inline-block text-left mr-2" id="dropdown-container">
            <button
              onClick={toggleDropdown}
              className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md w-156"
              id="menu-button"
              aria-expanded={isDropdownOpen}
              aria-haspopup="true"
            >
              Common Addresses
            </button>
            {isDropdownOpen && (
              <div
                className="origin-top-right absolute right-0 mt-2 w-156 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex="-1"
              >
                <div className="py-5" role="none">
                  {Object.entries(commonAddresses[arbitrum.id]).map(([name, address]) => (
                    <button
                      key={address}
                      onClick={() => {
                        copyToClipboard(address);
                        setIsDropdownOpen(false);
                      }}
                      className="text-white-700 block px-4 py-2 w-156 text-sm hover:bg-gray-500 w-full text-left"
                      role="menuitem"
                      tabIndex="-1"
                    >
                      {name}: {address}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <select
            onChange={(e) => handleNetworkSwitch(Number(e.target.value))}
            className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md"
          >
            <option value={arbitrum.id}>Arbitrum One</option>
            <option value={8453}>Base</option>
            <option value={1337}>Localhost</option>
          </select>
        </div>

        <Routes>
          <Route
            path="/"
            element={
              <>
                <div className="relative mb-6">
                  <button
                    onClick={connectWallet}
                    className="absolute top-0 right-0 bg-accent1 hover:bg-accent3 p-2 rounded-md text-white text-sm"
                  >
                    {walletAddress
                      ? `${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`
                      : "Connect Wallet"}
                  </button>
                  <h1 className="text-3xl font-bold text-center text-green-400">
                    Farm Function Call Creator
                  </h1>
                </div>
                <div className="farm-component bg-gray-800 p-4 rounded-md mb-4">
                  <h2 className="text-xl font-semibold text-green-400 mb-2">Farm</h2>
                  <div className="flex-grow">
                    <h2 className="text-sm italic text-white mb-2">
                      Clipboard allows for dynamic calldata. Clipboard is indexed as, (Call to copy
                      from), (return param to copy), and (input to paste to). (1, 0, 0) means to
                      copy the 0th return param from the 2nd call, and paste into the 0th param of
                      this call.
                    </h2>
                    <h2 className="text-sm italic text-white mb-2">
                      Copy and paste params are restricted to 32-byte sizes, and can only be copied
                      from and pasted into 32-byte increments. User should be aware of how arrays
                      are encoded to properly paste data.
                    </h2>
                  </div>
                  {calls.map((call, index) => (
                    <div className="flex flex-col md:flex-row gap-4 items-start">
                      {/* Column 1 - FunctionCall/AdvancedPipeCall and Eth input */}
                      <div className="w-full md:w-2/3 self-start">
                        <div key={index} className="border border-gray-600 p-4 rounded-md mb-4">
                          {call.function !== "advancedPipe" ? (
                            <div>
                              <FunctionCall
                                call={call}
                                availableFunctions={availableFunctions}
                                onFunctionChange={(value) => handleFunctionChange(index, value)}
                                onInputChange={(inputIndex, inputValue) => {
                                  const updatedCalls = [...calls];
                                  updatedCalls[index].inputs[inputIndex] = inputValue;
                                  setCalls(updatedCalls);
                                }}
                              />
                              <div className="flex justify-between items-start mt-2">
                                {calls.length > 1 && (
                                  <button
                                    onClick={() => handleRemoveFarmCall(index)}
                                    className="ml-4 bg-red-500 hover:bg-red-600 text-white p-2 rounded-md h-10"
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            </div>
                          ) : (
                            <>
                              {call.pipeCalls.map((pipeCall, pipeIndex) => (
                                <AdvancedPipeCall
                                  key={pipeIndex}
                                  availableFunctions={availablePipeFunctions}
                                  pipeCallData={pipeCall}
                                  onChange={(updatedPipeCall) =>
                                    handleAdvancedPipeCallChange(index, pipeIndex, updatedPipeCall)
                                  }
                                  onRemove={() => handleRemovePipeCall(index, pipeIndex)}
                                />
                              ))}

                              <div className="flex justify-between">
                                <input
                                  type="text"
                                  placeholder="Eth amount"
                                  value={call.uint256Value || ""}
                                  onChange={(e) => handleUint256Change(index, e.target.value)}
                                  className="mt-4 p-2 rounded-md text-black w-full bg-blue-200 mr-2 ml-2"
                                />

                                <button
                                  onClick={() =>
                                    handleAdvancedPipeCallSubmit(index, {
                                      target: "",
                                      selectedFunction: "",
                                      functionInputs: [],
                                      clipboard: {
                                        typeId: "0x02",
                                        returnPasteParams: [
                                          {
                                            returnDataIndex: "",
                                            copyIndex: "",
                                            pasteIndex: "",
                                          },
                                        ],
                                      },
                                    })
                                  }
                                  className="mt-4 bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md mr-2 ml-2"
                                >
                                  Add Advanced Pipe Call
                                </button>

                                <button
                                  onClick={() => handleRemoveCall(index)}
                                  className="mt-4 bg-red-500 hover:bg-red-600 text-white p-2 rounded-md mr-2 ml-2"
                                >
                                  Remove Advanced Pipe Call
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      {/* Column 2 - Clipboard Data */}
                      <div className="w-full md:w-1/3 self-start">
                        <ClipboardData
                          clipboard={call.clipboard}
                          onClipboardChange={(field, value) =>
                            handleClipboardChange(index, field, value)
                          }
                          onClipboardDataChange={(paramIndex, field, value) =>
                            handleClipboardDataChange(index, paramIndex, field, value)
                          }
                        />
                      </div>
                    </div>
                  ))}
                  <button
                    onClick={addCall}
                    className="mt-4 bg-accent1 hover:bg-accent3 p-2 rounded-md w-full text-white"
                  >
                    Add Farm Call
                  </button>
                </div>
                <div className="calldata-component bg-gray-800 p-4 rounded-md mb-4">
                  <h2 className="text-xl font-semibold text-green-400 mb-2">Calldata</h2>

                  <button
                    onClick={generateCallData}
                    className="bg-accent1 hover:bg-accent3 p-2 rounded-md w-full mb-4 text-white"
                  >
                    Generate Call Data
                  </button>
                  <div className="bg-secondary text-white p-4 rounded-md shadow-md mb-4">
                    <h2 className="text-xl font-semibold">Encoded Call Data</h2>
                    <textarea
                      value={encodedCallData}
                      readOnly
                      className="mt-2 p-2 rounded-md w-full h-40 bg-accent1 text-white"
                    />
                  </div>
                </div>
                {signer && <Tractor advancedFarmCalldata={encodedCallData} signer={signer} />}
                <TractorExecutor beanstalkContract={beanstalkContract} />
                <AbiInput onAddAbi={handleAddCustomAbiForPipeCall} />
              </>
            }
          />
          <Route path="/tractor-blueprints" element={<AddContent />} />
          <Route
            path="/approvals"
            element={
              <>
                <h1 className="text-3xl font-bold text-center text-green-400 mb-6">Approvals</h1>
                {signer ? (
                  <SignatureCreator signer={signer} />
                ) : (
                  <p className="text-center text-red-400">
                    Please connect your wallet to use this feature.
                  </p>
                )}
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default memo(App);

// Configuration for known contract addresses and their allowed functions
import {
  DIAMOND,
  GARDEN,
  PINTO,
  PINTO_ETH,
  PINTO_CBETH,
  PINTO_CBBTC,
  PINTO_USDC,
  PINTO_WSOL,
} from "../Libraries/Constants";

export const CONTRACT_TYPES = {
  Pinto: {
    name: "Pinto",
    allowedFunctions: ["transferToken"],
  },
  Garden: {
    name: "Garden",
    allowedFunctions: [
      "plantAndConvert",
      "harvestAndSow",
      "bytes32Switch",
      "callBSandCheckValue",
      "callBSandCheckValue2",
      "checkForBothThings",
      "check",
      "checkLogic",
      "callBSandCheckValue",
      "callBSandCheckValue2",
      "eq",
      "gte",
      "lte",
      "mod",
      "mul",
      "mulDiv",
      "add",
      "div",
      "sub",
    ],
  },
  Well: {
    name: "Well",
    allowedFunctions: [
      "totalSupply",
      "balanceOf",
      "approve",
      "transfer",
      "swapFrom",
      "getSwapOut",
      "swapTo",
      "getSwapIn",
      "shift",
      "getShiftOut",
      "addLiquidity",
      "getAddLiquidityOut",
      "removeLiquidity",
      "getRemoveLiquidityOut",
      "removeLiquidityOneToken",
      "getRemoveLiquidityOneTokenOut",
      "removeLiquidityImbalanced",
      "getRemoveLiquidityImbalancedIn",
      "sync",
      "getSyncOut",
      "skim",
      "getReserves",
      "permit",
    ],
  },
  Erc20: {
    name: "Erc20",
    allowedFunctions: ["totalSupply", "balanceOf", "approve", "transfer", "transferFrom"],
  },
};

// Map of known addresses to their types
export const KNOWN_ADDRESSES = {
  [DIAMOND]: "Pinto",
  [GARDEN]: "Garden",
  [PINTO]: "Erc20",
  [PINTO_ETH]: "Well",
  [PINTO_CBETH]: "Well",
  [PINTO_CBBTC]: "Well",
  [PINTO_USDC]: "Well",
  [PINTO_WSOL]: "Well",
  "0x4200000000000000000000000000000000000006": "Erc20",
  "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913": "Erc20",
  "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22": "Erc20",
  "0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf": "Erc20",
};

// New: Add readable names for the addresses
export const ADDRESS_NAMES = {
  [DIAMOND]: "Pinto Protocol",
  [GARDEN]: "Garden",
  [PINTO]: "Pinto ERC20",
  [PINTO_ETH]: "Pinto:Eth Well",
  [PINTO_CBETH]: "Pinto:cbETH Well",
  [PINTO_CBBTC]: "Pinto:cbBTC Well",
  [PINTO_USDC]: "Pinto:Usdc Well",
  [PINTO_WSOL]: "Pinto:Wsol Well",
  "0x4200000000000000000000000000000000000006": "Weth",
  "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913": "Usdc",
  "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22": "cbETH",
  "0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf": "cbBTC",
  "0x1C61629598e4a901136a81BC138E5828dc150d67": "Wsol",
};

const AdvancedFarm = require("./AdvancedFarm");
const AdvancedPipe = require("./AdvancedPipe");
const beanstalkAbi = require("../Abi/beanstalk.json");

class BeanstalkFarm {
  constructor(targetAbis) {
    this.advancedFarm = new AdvancedFarm(beanstalkAbi);
    this.advancedPipe = new AdvancedPipe(targetAbis);

    this._exposeMethods(this.advancedFarm);
    this._exposeMethods(this.advancedPipe);
  }

  _exposeMethods(instance) {
    Object.getOwnPropertyNames(Object.getPrototypeOf(instance))
      .filter(
        (prop) => typeof instance[prop] === "function" && prop !== "constructor"
      )
      .forEach((method) => {
        this[method] = (...args) => instance[method](...args);
      });
  }
}

module.exports = BeanstalkFarm;
